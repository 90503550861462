




















import { Component, Vue } from 'vue-property-decorator'
import moduleHeader from '@/components/moduleHeader/index.vue'
import { fetchExportStatus } from '@/api/download'
import { CommonMudule } from '@/store/modules/common'
import { cloneDeep } from 'lodash'

@Component({
  name: 'download',
  components: {
    moduleHeader
  }
})
export default class extends Vue {
  isLoading = false
  selectList = []
  list: Array<any> = []
  created() {
    this.initFetch()
  }
  get filterList() {
    if (CommonMudule.authArr.length > 0) {
      const arr = cloneDeep(this.list)
      for (let i = arr.length - 1; i >= 0; i--) {
        if (
          arr[i].offType &&
          CommonMudule.authArr.includes(arr[i].offType as string)
        ) {
          arr.splice(i, 1)
        }
      }
      return arr
    }
    return this.list
  }

  async initFetch() {
    const res = await fetchExportStatus({ customerId: CommonMudule.customerId })
    if (res.success && res.statusCode === 0) {
      this.list = [
        {
          key: 'family',
          name: '财富体检',
          disabled: res.data.financialAnalysis,
          offType: 'assetAnalysis'
        },
        {
          key: 'invest',
          name: '投资组合规划',
          disabled: res.data.investmentPlan,
          offType: 'invest'
        },
        {
          key: 'base',
          name: '家庭基础保障规划',
          disabled: res.data.family,
          offType: 'base'
        },
        {
          key: 'child',
          name: '子女教育规划',
          disabled: res.data.childrenEdu,
          offType: 'childEdu'
        },
        {
          key: 'pension',
          name: '退休养老规划',
          disabled: res.data.pension,
          offType: 'pension'
        }
      ]
    }
  }
  submit() {
    let url = '/print?keys=' + this.selectList.join(',')
    try {
      if (process.env.NODE_ENV === 'production') {
        url = '/amc' + url
      }
    } catch (e) {
      // err
    }
    window.open(url, '_blank')
  }
}
